import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);

axios.defaults.withCredentials = true;

// axios.defaults.baseURL = "http://backend-umum.com:3029";
// app.config.globalProperties.$appUrl = "http://short.com:210";
// axios.defaults.baseURL = "http://backend-umum.local";
// app.config.globalProperties.$appUrl = "https://short.local";
axios.defaults.baseURL = "https://localbackend-umum.sitepgri.com";
app.config.globalProperties.$appUrl = "https://sitepgri.com";

app.use(router);
app.use(VueAxios, axios);

app.mount("#app");
