<template>
  <div class="py-5">
    <h3>URL Redirect Page</h3>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);">
      <div v-if="memuat" class="d-flex align-items-center">
        <span role="status">Memuat...</span>
        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
        <div class="spinner-grow spinner-grow-sm" aria-hidden="true"></div>
      </div>
      <div v-else-if="url">Anda akan dialhikan ke halaman <br />
        <a :href="url.long_url">{{ url.deskripsi }}</a>
      </div>
      <div v-else>Tautan tidak ditemukan.<br />
        <code class="bg-secondary-subtle">sitepgri.com/{{ $route.params.short }}</code>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      memuat: true,
      url: null
    }
  },
  mounted() {
    this.getRedirectUrl()
  },
  methods: {
    async getRedirectUrl() {
      if (!this.$route.params) {
        this.memuat = false;
      } else {
        try {
          const response = await this.axios.post("/get-url", this.$route.params);
          if (response.data) {
            this.url = response.data.url;
          }
        } catch (err) {
          console.log(err);
        }
        setTimeout(() => {
          this.memuat = false;
          if (this.url) {
            window.location.href = this.url.long_url;
          }
        }, 250)
      }
    }
  }
}
</script>
